<!-- notifications -->
<template>
  <div class="section-nopad">
    <Section1 :title="title" :hasFooter="false"  :menuData="['Edit']" @menuCallback='handleEditLocation'>
	
      <ul class="cardcontent-info" v-loading="loading">
        <li v-for="(item, index) in tableData" :key="index">
          <div class="lable">{{ item.value }}</div>
          <i class="iconfont iconedit" @click="handleEdit(item)"></i>
        </li>
        <div class="wraper">
          <div class="append">
            <span class="text" @click="handleAddOption()">Add location</span>
          </div>
        </div>
      </ul>
	  	  <!-- <div slot="icon" class="sectionIcon"> <i class="iconfont iconedit" @click="handleEdit"></i></div> -->
    </Section1>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      title: "Locations",
      tableData: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
	  handleEditLocation() {
	    this.$router.push({
	      name: "tablePageLocations",
	      query: { userId: this.user.userId },
	    });
	  },
    _getData() {
      let data = {
        userId: this.user.userId,
        dataLevel: "location",
        dataType: "location",
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleEdit(item) {
      this.$router.push({
        name: "settingLocationInfo",
        query: {
          id: item.id,
        },
      });
    },
    handleAddOption() {
      this.$router.push({
        name: "settingLocationInfo",
      });
    },
  },
  created() {
    this._getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.wraper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 57px;
  line-height: 57px;
  font-size: 14px;
  color: #202124;
  font-family: Roboto;
  padding-left: 20px;
  .append {
    font-family: Roboto;
    font-size: 14px;
    color: #1a73e8;
    cursor: pointer;
  }
}

</style>